import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-list" }
const _hoisted_2 = { class: "card border-light health-leads-tasks" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card-body filters-block" }
const _hoisted_5 = { class: "card border-light mt-5" }
const _hoisted_6 = { class: "card-body card-custom-spacing-tblf-10" }
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_vc_invoice_first_layer = _resolveComponent("vc-invoice-first-layer")!
  const _component_vc_invoice_table = _resolveComponent("vc-invoice-table")!
  const _component_vc_invoice_pagination = _resolveComponent("vc-invoice-pagination")!
  const _component_vc_invoice_details = _resolveComponent("vc-invoice-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vc_socket, {
      channel: 'health-channel-'+_ctx.store.getters.myId,
      listen: "Health.HealthInvoiceSocketEvent",
      onAction: _ctx.silentReferesh
    }, null, 8, ["channel", "onAction"]),
    _createVNode(_component_vc_socket, {
      channel: "health-invoice-channel",
      listen: "Health.HealthInvoiceCountSocketEvent",
      onAction: _ctx.silentReferesh
    }, null, 8, ["onAction"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_vc_invoice_first_layer)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_vc_invoice_table, {
            "all-invoices": "all_invoices_with_pending",
            allowUpdateInvoice: "",
            hideChild: ""
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_vc_invoice_pagination)
        ])
      ])
    ]),
    _createVNode(_component_vc_invoice_details)
  ], 64))
}